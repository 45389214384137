import * as React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';

export const SellerEdit: React.FC = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);
