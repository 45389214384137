import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  SaveButton,
  Toolbar,
  useRedirect,
  useNotify,
} from 'react-admin';
import { FormGlobalError } from '../../components/FormGlobalError';

const JobTitleEditToolbar: React.FC = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const JobTitleEdit: React.FC = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify('肩書を更新しました');
    redirect('/jobTitles');
  };

  return (
    <Edit mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
      <SimpleForm toolbar={<JobTitleEditToolbar />}>
        <FormGlobalError />
        <TextInput source="name" validate={required()} fullWidth disabled />
        <BooleanInput source="billable" />
      </SimpleForm>
    </Edit>
  );
};
