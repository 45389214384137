import * as React from 'react';
import {
  ArrayInput,
  Edit,
  NumberInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  Toolbar,
  ReferenceInput,
  required,
} from 'react-admin';
import DatetimeInput from 'src/components/DatetimeInput';
import CustomDateTimeInput from 'src/components/DatetimeInput';
import { FormGlobalError } from 'src/components/FormGlobalError';
import HiddenInput from 'src/components/HiddenInput';
import { LabsWandhD1OptionInput, PermissionsInput } from './inputs';
import { contractUserCountTypes, pricingTypes } from './field';
import { pick } from 'lodash';
import { Divider } from '@mui/material';

const allowedFields = [
  'name',
  'trialFrom',
  'trialUntil',
  'paidFrom',
  'paidUntil',
  'permissions',
  'sellerId',
  'pricingType',
  'contractUserCountType',
  'maxContractUserCount',
  'packs',
  'labsWandhPlans',
  'updatedAtMillis',
  'lawFirmTieredPricingCondition',
  'labsWandhD1Option',
];

const OrganizationEditToolbar: React.FC = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const OrganizationEdit: React.FC = () => {
  // TODO: 引数の`data: any`を適切な型にする
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transformForSave = (data: any) => {
    const filteredData = pick(data, allowedFields);

    const getD1OptionUserValidFrom = (userValidFrom: Date | null, optionValidFrom: Date | null) => {
      if (userValidFrom || optionValidFrom === null) {
        return userValidFrom;
      }

      const hasOptionValidFromPassed = optionValidFrom.getTime() < new Date().getTime();
      if (hasOptionValidFromPassed) {
        return new Date();
      }

      return optionValidFrom;
    };

    const getD1OptionUserValidUntil = (userValidUntil: Date | null, optionValidUntil: Date | null) => {
      return userValidUntil ?? optionValidUntil;
    };

    const isInvalidDate = (date: unknown) => {
      if (date === null || date === undefined) {
        return true;
      }
      return date instanceof Date && isNaN(date.getTime());
    };

    const labsWandhD1Option = {
      ...data.labsWandhD1Option,
      // TODO: 引数の`user: any`を適切な型にする
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      users: data.labsWandhD1Option.users.map((user: any) => ({
        ...user,
        validFrom: getD1OptionUserValidFrom(
          !isInvalidDate(user.validFrom) ? new Date(user.validFrom) : null,
          !isInvalidDate(data.labsWandhD1Option.validFrom) ? new Date(data.labsWandhD1Option.validFrom) : null,
        ),
        validUntil: getD1OptionUserValidUntil(
          !isInvalidDate(user.validUntil) ? new Date(user.validUntil) : null,
          !isInvalidDate(data.labsWandhD1Option.validUntil) ? new Date(data.labsWandhD1Option.validUntil) : null,
        ),
      })),
    };

    return {
      ...filteredData,
      labsWandhD1Option,
    };
  };

  return (
    <Edit redirect="show" mutationMode="pessimistic" transform={transformForSave}>
      <SimpleForm toolbar={<OrganizationEditToolbar />}>
        <TextField source="oid" />

        <FormGlobalError />

        <HiddenInput source="updatedAtMillis" />
        <TextInput source="name" />
        <CustomDateTimeInput source="trialFrom" />
        <CustomDateTimeInput source="trialUntil" />
        <CustomDateTimeInput source="paidFrom" />
        <CustomDateTimeInput source="paidUntil" />
        <ReferenceInput source="sellerId" reference="sellers" validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="pricingType" choices={pricingTypes} defaultValue={null} />
        <SelectInput
          source="contractUserCountType"
          choices={contractUserCountTypes}
          defaultValue={null}
          sx={{ minWidth: 220 }}
        />
        <NumberInput source="maxContractUserCount" min="0" />

        <PermissionsInput />

        <ArrayInput source="packs">
          <SimpleFormIterator disableRemove disableReordering inline>
            <NumberInput source="packId" />
            <CustomDateTimeInput source="validFrom" />
            <CustomDateTimeInput source="validUntil" />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput validate={required()} source="labsWandhPlans">
          <SimpleFormIterator disableRemove disableReordering inline>
            <ReferenceInput
              source="basePlanId"
              reference="labsWandhPlans"
              validate={required()}
              sort={{ field: 'id', order: 'ASC' }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              source="addOnPlanId"
              reference="labsWandhPlans"
              allowEmpty
              sort={{ field: 'id', order: 'ASC' }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput source="maxAddOnPlanUsers" />
            <DatetimeInput source="activeFrom" validate={required()} />
            <DatetimeInput source="activeUntil" />
          </SimpleFormIterator>
        </ArrayInput>

        <LabsWandhD1OptionInput />

        <Divider sx={{ mb: 4 }} flexItem />
      </SimpleForm>
    </Edit>
  );
};
