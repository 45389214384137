import * as React from 'react';

import { List, Datagrid, TextField, ReferenceInput, AutocompleteInput, BooleanField, EditButton } from 'react-admin';
import BadgeIcon from '@mui/icons-material/Badge';
import DatetimeField from 'src/components/DatetimeField';
export const JobTitleIcon = BadgeIcon;
export { JobTitleEdit } from './update';

const filters = [
  <ReferenceInput source="organizationId" reference="organizations" alwaysOn>
    <AutocompleteInput optionValue="id" optionText="name" label="resources.organizations.name" sx={{ width: 450 }} />
  </ReferenceInput>,
];

export const JobTitleList: React.FC = () => (
  <List filters={filters} perPage={100} sort={{ field: 'id', order: 'DESC' }} empty={false}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <BooleanField source="billable" sortable={false} />
      <DatetimeField source="createdAt" sortable={false} />
      <DatetimeField source="updatedAt" sortable={false} />
      <DatetimeField source="deletedAt" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);
