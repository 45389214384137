import * as React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

export const SellerShow: React.FC = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);
