import * as React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

export const SellerCreate: React.FC = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Create>
);
